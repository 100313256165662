import "./App.scss";
import TestimonialSection from "./components/testimonials-section/TestimonialSection";

function App() {
  return (
    <div className="app">
      <TestimonialSection />
    </div>
  );
}

export default App;
